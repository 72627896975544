import React from 'react';
import { Slide } from 'react-slideshow-image';
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import 'react-slideshow-image/dist/styles.css'
import BannerTeam from '../components/BannerTeam'
import '.././assets/css/slideshow.css';
import robot from '../assets/images/other/robot.jpeg'

export default function robotics() {
  const style = {
    width: '90vw',
    height: 'auto',
    // borderRadius: '10px',
    // borderStyle: 'solid',
    // borderColor: 'white',
    // borderWidth: '8px'
    boxShadow: '#131520 0 0 20px 10px',
  }

  return (
    <div>
      <Layout>
        <Helmet>
          <title>Krypton Warriors</title>
          <meta name="description" content="Landing Page" />
          <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>
        </Helmet>

        <BannerTeam />
        <div>
          <div id="main">
            <section id="one">
              <div className="inner">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>
                  Check out our robot for the 2022-2023 season.
                </p>
              </div>
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <img
                  src={robot}
                  style={style}
                  alt="connectTimeline"
                ></img>
                <br></br>
                <br></br>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </div>
  )
}
